import * as React from 'react';
import './DelayedText.scss';

export interface DelayedTextProps {
    text: string;
    delay: number;
    callback?: () => void;
}

export interface DelayedTextStates {
    index: number;
}

export class DelayedText extends React.Component<DelayedTextProps, DelayedTextStates> {

    constructor(props: DelayedTextProps) {
        super(props);
        this.state = {
            index: 0,
        };
        const printer = setInterval(
            this.printOut.bind(this),
            this.props.delay,
            () => {
                clearInterval(printer);
                this.props.callback && this.props.callback();
            },
        );
    }

    private printOut(callback: () => void) {
        const { index } = this.state;
        if (index < this.props.text.length) {
            this.setState({
                index: index + 1,
            });
        } else {
            callback();
        }
    }

    public render() {
        return (<span className="dtext">{this.props.text.substring(0, this.state.index)}</span>);
    }
}