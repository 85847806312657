import * as React from 'react';
import './App.scss';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { MainPrompt } from './components/prompts';
import { DelayedText } from './components/DelayedText';

interface AppStates {
    history: JSX.Element[];
    propmtState: number;
}

export class App extends React.Component<{}, AppStates> {

    private _end: HTMLElement;

    constructor(props: {}) {
        super(props);
        this.state = {
            history: [],
            propmtState: 1,
        };
    }

    public componentDidUpdate() {
        this.scrollToBottom();
    }

    private addHistory(s: JSX.Element) {
        this.setState({
            history: [...this.state.history, s],
        });
    }

    private pageStateBump() {
        this.setState({
            propmtState: this.state.propmtState + 1,
        });
    }

    public render() {
        const asciiArt = [
            `   /\\                         _      _    _        _        `,
            `  /  \\                       | |    | |  | |      (_)       `,
            ` / /\\ \\   ____  ____    ___  | |  _ | |  | |       _  ____  `,
            `| |__| | / ___)|  _ \\  / _ \\ | | / || |  | |      | ||  _ \\ `,
            `|  __  || |    | | | || |_| || |( (_| |  | |_____ | || | | |`,
            `|_|  |_||_|    |_| |_| \\___/ |_| \\____|  |_______)|_||_| |_|`,
        ].join("\n");

        const introduction = [
            "Hello. Welcome to my personal site - a site containing most of what you expect; " + 
                "a place embedding some suprises; a playground filled with my imagination.",
            "If you are also a geek, feel free to tweak around - but be aware what you type...",
            "Else, a more human (non-geek) friendly version is coming up...",
        ].join("\n");

        const regions = [
            <p className="ascii-art"><DelayedText
                text={asciiArt}
                delay={2}
                callback={this.pageStateBump.bind(this)}
            /></p>,
            <p className="introduction-header"><DelayedText
                text={introduction}
                delay={15}
                callback={this.pageStateBump.bind(this)}
            /></p>,
            this.renderPromptRegion(),
        ]
        return <div className="main">
            {regions.slice(0, this.state.propmtState)}
            <div ref={(e) => this._end = e}/>
        </div>;
    }

    private renderPromptRegion() {
        return <div>
            {this.state.history}
            <MainPrompt
                promptText={`geek_visitor@${window.location.hostname}:~$ `}
                submitHistory={this.addHistory.bind(this)}
                />
        </div>;
    }

    private scrollToBottom() {
        this._end.scrollIntoView({behavior: 'smooth'});
    }
}