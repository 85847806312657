import * as React from 'react';
import * as _ from 'lodash';
import * as classNames from 'classnames';
import './Table.scss';

export interface TableRowProps {
    entries: (string | JSX.Element)[];
}

export class TableRow extends React.Component<TableRowProps, {}> {

    private level: number;
    private entries: JSX.Element[];

    constructor(props: TableRowProps) {
        super(props);
        this.entries = props.entries.map(item => <td>{item}</td>);
        this.level = this.entries.length;
    }

    public testLevel(expectedLevel: number) {
        return this.level === expectedLevel;
    }

    public render() {
        return <tr>{this.entries}</tr>;
    }

    public static of(entries: (string | JSX.Element)[]) {
        return new TableRow({entries});
    }
}

export interface TableDisplayProps {
    rows: TableRow[];
    enforceLevel?: number;
}

export class TableDisplay extends React.Component<TableDisplayProps, {}> {

    private rows: TableRow[];

    constructor(props: TableDisplayProps) {
        super(props);
        if (props.enforceLevel != null) {
            this.rows = props.rows.filter(row => row.testLevel(props.enforceLevel));
        } else {
            this.rows = props.rows;
        }
    }

    public render() {
        return <table>{this.rows.map(r => r.render())}</table>;
    }
}