import * as React from 'react';
import * as _ from 'lodash';
import { AbstractPrompt, AbstarctPromptStates, AbstractPromptProps } from "./AbstractPrompt";
import { TabularDisplay } from "../TabularDisplay";
import { MockFileSystem } from '../../apps/MockFileSystem';

interface CommandSettings {
    run: (tokens: string[]) => JSX.Element[];
    completeOverride?: {[k: number]: (text: string, token: string) => string[]};
}

interface MainPromptProps extends AbstractPromptProps {}
interface MainPromptStates extends AbstarctPromptStates {}

export class MainPrompt extends AbstractPrompt<MainPromptProps, MainPromptStates> {

    private fs: MockFileSystem;

    private COMMANDS: {[k: string]: CommandSettings} = {
        "whoami": {
            run: (tokens) => [
                <p>Arnold: A Software Engineer, aka "Modern Magician", working for Palantir Technologies. Constructs Full Stack, from front end to service, from application to infrastructure. Tweak around to know more about him :)</p>,
                <p>Also Arnold: Full time classical music enthusiast, specialized in Beethoven, Schubert.</p>,
                <p>Also Arnold: Part time soccer (football) fan. Part time Pokemon fan.</p>,
                <pre> </pre>,
                <p>-fsh: geek_visitor</p>,
            ],
        },
        "pwd": {
            run: (tokens) => {
                const elems = [];
                if (tokens.length > 1) {
                    elems.push(
                        <p className="main-prompt-special hidden-wannabe">You know pwd don't accept arguments...</p>
                    );
                }
                elems.push(this.fs.pwd());
                return elems;
            },
        },
        "ls": {
            run: (tokens) => {
                const options = (tokens.length > 0 && tokens[0].startsWith("-")) ? tokens.shift() : "";
                const target = tokens[0];
                return [this.fs.ls(options, target)];
            },
        },
        "cd": {run: (tokens) => [this.fs.cd(tokens[0])]},
        "cat": {run: (tokens) => [this.fs.cat(tokens[0])]},
    };

    constructor(props: MainPromptProps) {
        super(props);
        this.state = {
            ... AbstractPrompt.DEFAULT_STATES,
        };
        this.fs = new MockFileSystem;
    }

    public processText(): JSX.Element[] {
        const { text } = this.state;
        let resultElements = [(<pre>{text}</pre>)];
        const respTooGeek = "While this may seem like terminal a lot, it is not :) ";
        if (text.indexOf(";") > -1) {
            resultElements.push(
                <p className="main-prompt-special hidden-wannabe">{respTooGeek + "All ';' will be ignored or treated as regular char."}</p>
            );
        }
        const tokens = text.trim().split(" ");
        const leading = tokens[0].replace(";", "");
        
        if (leading.length === 0) {
            return resultElements;
        }
        
        const action = this.COMMANDS[leading];

        if (action == null) {
            resultElements.push(
                <p className="main-prompt-special">{`-fsh: ${leading}: command not found`}</p>
            );
        } else {
            resultElements = [
                ... resultElements,
                ... action.run(tokens.slice(1))
            ];
        }

        return resultElements;
    }

    public completeText(text: string, startIndex: number, cursorIndex: number) {
        const preCompleteTokens = text.substring(0, startIndex).trim().split(" ");
        if (preCompleteTokens.length > 1) {
            const actionToken = preCompleteTokens[0];
            const completeIndex = preCompleteTokens.length;
            const completeAction = this.COMMANDS[actionToken].completeOverride;
            if (completeAction != null && completeAction[completeIndex] != null) {
                return completeAction[completeIndex](text, text.substring(startIndex, startIndex + cursorIndex));
            }
        }
        return this.defaultCompleteText(text, startIndex, cursorIndex);
    }

    private defaultCompleteText(text: string, startIndex: number, cursorIndex: number) {
        const prefix = text.substring(startIndex, startIndex + cursorIndex);
        if (startIndex === 0 || text.substring(0, startIndex).trim() === "") {
            return _.keys(this.COMMANDS).filter(cmd => cmd.startsWith(prefix)).map(cmd => `${cmd} `);
        }
        return this.fs.getByPrefix(prefix);
    }
}
