import * as React from 'react';
import './prompt.scss';
import { longestCommonPrefix } from "../../utils/textUtils";
import { TabularDisplay } from '../TabularDisplay';

export interface AbstractPromptProps {
    promptText: string;
    submitHistory: (elem: JSX.Element) => void;
}

export interface AbstarctPromptStates {
    text: string;
    currentBuffer: string;
    memory: string[];
    memoryPointer: number;
    justTabbed: boolean;
}

export abstract class AbstractPrompt<P extends AbstractPromptProps, S extends AbstarctPromptStates>
    extends React.Component<P, S> {

    private inputRef: HTMLInputElement = null;

    public static DEFAULT_STATES: AbstarctPromptStates = {
        text: "",
        currentBuffer: "",
        memory: [],
        memoryPointer: 0,
        justTabbed: false,
    };

    public abstract processText(): JSX.Element[];

    public abstract completeText(text: string, startIndex: number, cursorIndex: number): string[];

    private onKeyEnter(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.keyCode === 10 || event.keyCode === 13) {
            const processed = this.processText();
            this.props.submitHistory(<div className="prompt-block">
                    <div className="prompt-line">
                        <p>{this.renderDangle()}</p>
                        {processed.length > 0 && <div className="prompt-input-layer">{processed[0]}</div>}
                    </div>
                    <div className="prompt-response">{processed.length > 1 && processed.slice(1)}</div>
                </div>);
            if (this.state.text.length > 0) {
                this.setState({
                    text: "",
                    currentBuffer: "",
                    memory: [
                        ... this.state.memory,
                        this.state.text,
                    ],
                    memoryPointer: 0,
                    justTabbed: false,
                });
                event.currentTarget.value = "";
            } else {
                this.setState({
                    justTabbed: false,
                });
            }
        } else if (event.keyCode === 38 || event.keyCode === 40) {   // Up (38) or Down (40)
            const { memory, memoryPointer, currentBuffer } = this.state;
            const currentPointer = memoryPointer + ((event.keyCode == 38) ? -1 : 1);
            if (-currentPointer <= memory.length && currentPointer <= 0) {
                this.setState({
                    text: event.currentTarget.value = (currentPointer == 0) ? currentBuffer : memory[memory.length + currentPointer],
                    memoryPointer: currentPointer,
                    justTabbed: false,
                });
            }
        } else if (event.keyCode !== 9) {
            this.setState({
                text: event.currentTarget.value,
                currentBuffer: event.currentTarget.value,
                justTabbed: false,
            });
        }   // Do not handle tab. Tab has to be handled with another event type.
    }

    private maybeActionOnTab(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.keyCode === 9) {
            const { memory, memoryPointer, justTabbed } = this.state;
            const { selectionStart, value } = event.currentTarget;
            const wordStart = value.lastIndexOf(" ", selectionStart) + 1;
            const options = this.completeText(value, wordStart, selectionStart - wordStart);
            if (justTabbed && options.length !== 1) {
                this.props.submitHistory(<div className="prompt-block">
                    <div className="prompt-line">
                        <p>{this.renderDangle()}</p>
                        <div className="prompt-input-layer"><pre>{value}</pre></div>
                    </div>
                <div className="prompt-response">{
                    (options.length > 1) 
                        ? <TabularDisplay items={options.sort()} colorScheme={[]}/> 
                        : <p className="hidden-wannabe">{"No suitable autocomplete"}</p>
                }</div>
                </div>);
            } else if (options.length !== 0) {
                const completePortion = (options.length === 1) ? options[0] : longestCommonPrefix(options);
                const completedText = `${value.substring(0, wordStart)}${completePortion}${value.substring(selectionStart)}`;
                this.setState({
                    text: event.currentTarget.value = completedText,
                    currentBuffer: completedText,
                    justTabbed: options.length !== 1,   // the only complete option case should never mark this as justTabbed
                }, () => {
                    const cursorIndex = wordStart + completePortion.length;
                    this.inputRef.setSelectionRange(cursorIndex, cursorIndex);
                });
            } else {
                this.setState({
                    justTabbed: true,
                });
            }
            event.preventDefault();
        }
    }

    private renderDangle() {
        return (<span className="dangle-prompt">{this.props.promptText}</span>);
    }

    private setInputRef(elem: HTMLInputElement) {
        this.inputRef = elem;
    }

    public render() {
        return (<div className="prompt-block">
            <div className="prompt-line">
                <p>{this.renderDangle()}</p>
                <div className="prompt-input-layer">
                    <input ref={this.setInputRef.bind(this)} onKeyDown={this.maybeActionOnTab.bind(this)} onKeyUp={this.onKeyEnter.bind(this)} autoFocus/>
                </div>
            </div>
        </div>);
    }
}

