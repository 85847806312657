export interface MockBaseFile {
    type: string;
    name: string;
    size: number;
    lastEdit: Date;
    permission: number;
}

export interface MockRegFile extends MockBaseFile {
    type: "reg";
    content: JSX.Element;
}

export interface MockFolder extends MockBaseFile {
    type: "folder";
    contents: {[k: string]: MockBaseFile};
}

export function mockFileOf(fileName: string, content: JSX.Element, lastEdit?: Date): MockRegFile {
    const finalLastEdit = (lastEdit == null) ? new Date(2020, 1, 14, 4, 49, 53) : lastEdit;
    return {
        type: "reg",
        name: fileName,
        content,
        size: 5,
        lastEdit: finalLastEdit,
        permission: 0o644,
    }
}

export function isRegFile(file: MockBaseFile): file is MockRegFile {
    return file.type == "reg";
}

export function mockFolderOf(folderName: string, contents: MockBaseFile[], lastEdit?: Date): MockFolder {
    const finalLastEdit = (lastEdit == null) ? new Date(2020, 1, 14, 4, 49, 53) : lastEdit;
    const contentMap: {[k: string]: MockBaseFile} = {};
    contents.forEach(file => contentMap[file.name] = file);
    return {
        type: "folder",
        name: folderName,
        contents: contentMap,
        size: contents.length,
        lastEdit: finalLastEdit,
        permission: 0o755,
    };
}

export function isFolder(file: MockBaseFile): file is MockFolder {
    return file.type == "folder";
}

export function toPermString(perm: number) {
    let arr: string[] = [];
    for (let p = perm; p > 0; p >>= 3) {
        arr = [
            (p & 4) ? 'r' : '-',
            (p & 2) ? 'w' : '-',
            (p & 1) ? 'x' : '-',
            ... arr
        ];
    }
    return arr.join("");
}
