export function longestCommonPrefix(arr: string[]): string {
    if (arr.length <= 1) {
        return arr[0] || "";
    }
    const a = longestCommonPrefix(arr.slice(0, arr.length >> 1));
    const b = longestCommonPrefix(arr.slice(arr.length >> 1));
    const end = Math.min(a.length, b.length);
    let i = 0;
    while(i < end && a.charAt(i) === b.charAt(i)) {
        i++;
    }
    return a.substring(0, i);
}