import * as React from 'react';
import {
    MockBaseFile,
    MockFolder,
    MockRegFile,
    mockFileOf,
    mockFolderOf,
    isFolder,
    toPermString,
    isRegFile
} from './MockFileUtils';
import * as _ from 'lodash';
import { TabularDisplay } from '../components/TabularDisplay';
import { TableDisplay, TableRow } from '../components/Table';
import './MockFileSystem.scss';

export class MockFileSystem {
    private cwd: string[] = [];
    private static BASE_PATH = "/home/geek_visitor/about_arnold";
    private static ROOT: MockFolder = mockFolderOf(MockFileSystem.BASE_PATH, [
        mockFileOf("about_programming", <div>
            <p>Languages:	Java, Python, JavaScript / TypeScript, Go, C, Assembly, Objective-C, R, Matlab, Ruby</p>
            <p>Common Tools:	Apache Spark, Hadoop, Conda, React, Docker, Apache Arrow, Kerberos, Tensorflow, Django</p>
            <p>Database:	MySQL, PostgreSQL, Apache Hive, Cassandra, MongoDB</p>
        </div>),
        mockFileOf("education", <div>
            <p>Emory University, Aug 2013 - May 2017</p>
            <p>{"Bachelor’s degree of Science in Computer Science & Chemistry"}</p>
            <p>{"CS Major GPA: 3.90/4.00, Cumulative GPA: 3.83/4.00"}</p>
            <p>{"Related Coursework: Data Structures & Algorithms, Artificial Intelligence, Machine Learning, System Programming, Computer Security, Numerical Analysis, Computational Linguistics (NLP), Database Systems"}</p>
        </div>),
        mockFolderOf("classical_music", [
            mockFolderOf("playlist", [
                mockFileOf("readme", <div>You have discovered themed collections that I maintained. Hope you'd enjoy it</div>),
                mockFileOf("Tranquility", <div>
                        <p>Fit for a rainy day. Fit for a night drink. Fit for a blessful dream.</p>
                        <iframe src="https://open.spotify.com/embed/playlist/5z0nNwmTIbFJgdudMuZ1sX" width="300" height="380" frameBorder="0" allowTransparency={true} allow="encrypted-media"></iframe>
                    </div>),
            ]),
        ]),
    ]);

    public pwd() {
        return <p>{this.getFullCwd()}</p>;
    }

    public cd(path?: string) {
        return this.captureErrorToViz(() => this._cd(path || "/"));
    }

    public ls(options: string, path?: string) {
        return this.captureErrorToViz(() => this._ls(options, path));
    }

    public cat(path?: string) {
        return this.captureErrorToViz(() => {
            if (path == null) {
                throw Error("`cat` expects one argument");
            }
            const filePointer = this.verifyAndUsePath(this.parsePath(path));
            if (!isRegFile(filePointer)) {
                throw Error(`${path} is a folder`);
            }
            return filePointer.content;
        });
    }

    public getByPrefix(prefix: string): string[] {
        const partialPath = this.parsePath(prefix);
        if (prefix.length === 0 || prefix.charAt(prefix.length - 1) === "/") {
            partialPath.push("");
        }
        try {
            const dirFile = this.verifyAndUsePath(partialPath.slice(0, partialPath.length - 1));
            const filePrefix = partialPath[partialPath.length - 1];
            if (!isFolder(dirFile)) {
                return [];
            }
            return _.values(dirFile.contents)
                .filter(elem => elem.name.startsWith(filePrefix))
                .map(elem => `${prefix.substring(0, prefix.length - filePrefix.length)}${elem.name}${isFolder(elem) ? "/" : " "}`);
        } catch (e) {
            return [];
        }
    }

    private captureErrorToViz(call: () => JSX.Element): JSX.Element {
        try {
            return call.apply(this, []);
        } catch (e) {
            return <div className="err-msg">Error: {(e as Error).message}</div>;
        }
    }

    private getFullCwd = () => {
        return [MockFileSystem.BASE_PATH, ...this.cwd].join("/");
    }

    private parsePath = (path: string): string[] => {
        if (path === "") {
            return [... this.cwd];
        }
        return path.split("/").reduce((pathStack, chunk, idx) => {
            if (idx === 0 && (chunk === "" || chunk === "~")) {
                return [];
            }
            if (chunk === ".." && pathStack.length > 0) {
                return pathStack.slice(0, pathStack.length - 1);
            }
            if (chunk.length > 0 && chunk !== ".") {
                return [...pathStack, chunk];
            }
            return pathStack;
        }, [... this.cwd]);
    }

    private verifyAndUsePath = (parsedPath: string[]): MockBaseFile => {
        return parsedPath.reduce<MockBaseFile>((pointer, pathChunk) => {
            if (!isFolder(pointer)) {
                throw Error(`-fsh: "${pointer.name}" is not a directory.`);
            }
            if (pointer.contents[pathChunk] == null) {
                throw Error(`-fsh: "${pathChunk}" does not exist in "${pointer.name}"`);
            }
            return pointer.contents[pathChunk];
        }, MockFileSystem.ROOT);
    }

    private _cd(path: string): JSX.Element {
        const cwd = this.parsePath(path);
        const resolvedPointer = this.verifyAndUsePath(cwd);
        if (!isFolder(resolvedPointer)) {
            throw Error(`-fsh: cd: ${resolvedPointer.name}: Not a directory`);
        }
        this.cwd = cwd;
        return null;
    }
    
    private static lsItem(file: MockBaseFile, mode: string, nameOverride?: string) {
        const printName = (nameOverride != null) ? nameOverride : file.name;
        const fileTypeBit = isFolder(file) ? 'd' : '-';
        const permString = toPermString(file.permission);
        const fileAttribute = `${fileTypeBit}${permString}.`;
        const size = (!mode.includes("h")) ? file.size.toString() : ((size: number) => {
            let i = 0;
            for(; size < 1024; i++) {
                size >>= 10;
            }
            return `${size}${" KMGTP"[i]}`;
        })(file.size);
        const dateString = file.lastEdit.toDateString();
        const fileClassName = isFolder(file) ? "type-folder" : "type-regular";
        const fileNameElem = <div className={fileClassName}>{printName}</div>;
        return [fileAttribute, '1', 'geek_visitor', 'root', <div className="right-align">{size}</div>, dateString, fileNameElem];
    }

    private _ls(options: string, path?: string) {
        const targetPath = (path == null) ? this.cwd : this.parsePath(path);
        const filePointer = this.verifyAndUsePath(targetPath);
        const filesToPrint = (!isFolder(filePointer)) 
            ? [filePointer as MockRegFile]
            : _.values(filePointer.contents).filter(file => options.includes("a") || file.name.charAt(0) !== ".");
        const nameOverride = (isFolder(filePointer)) ? null : path;
        if (!options.includes("l")) {
            if (!isFolder(filePointer)) {
                return <TabularDisplay items={[path]} colorScheme={[]}/>;
            }
            const items = filesToPrint.map(file => file.name);
            if (options.includes("a")) {
                items.splice(0, 0, ".", "..");
            }
            return <TabularDisplay items={items} colorScheme={[]}/>;
        } else {
            if (!isFolder(filePointer)) {
                return <TableDisplay rows={[TableRow.of(MockFileSystem.lsItem(filePointer, options, nameOverride))]}/>
            }
            const parentPath = (targetPath.length === 1) ? targetPath : targetPath.slice(0, targetPath.length - 1);
            let rendered = filesToPrint.map(file => MockFileSystem.lsItem(file, options));
            if (options.includes("a")) {
                rendered = [
                    MockFileSystem.lsItem(filePointer, options, "."),
                    MockFileSystem.lsItem(this.verifyAndUsePath(parentPath), options, ".."),
                    ... rendered
                ];
            }
            return <TableDisplay rows={rendered.map(TableRow.of)}/>;
        }
    }
}