import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Store, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import './index.scss';
import { App } from './App';
import { Provider } from 'react-redux';


// const store = createStore(
//     rootReducer,
//     initialState,
//     applyMiddleware(
//         thunk, 
//         createLogger({
//             collapsed: true,
//         })
//     ),
// );

ReactDOM.render(
    // <Provider store={store}>
    //     <App />
    // </Provider>,
    <App />,
    document.getElementById('root')
);

