import * as React from 'react';
import './TabularDisplay.scss';
import * as _ from 'lodash';
import * as classNames from 'classnames';

export interface TabularDisplayProps {
    items: string[];
    colorScheme: string[];
}

export class TabularDisplay extends React.Component<TabularDisplayProps, {}> {

    private maxItemLength: number;

    constructor(props: TabularDisplayProps) {
        super(props);
        this.maxItemLength = _.max(props.items.map(item => item.length));
    }

    public render() {
        const style = {"width": `${this.maxItemLength * 1.5 + 3}ex`}
        const wrappedItems = this.props.items.map((item, idx) => 
            <div className={classNames(this.props.colorScheme[idx], "tabular-item")} style={style}>{item}</div>);

        return <div className="tabular-table">{wrappedItems}</div>;
    }
}